<script>
import VueApexCharts from 'vue-apexcharts'
import Vue from "vue";
import ApiSecure from "@/services/api/api-secure";
import Field from "@/components/Field.vue";
import ApiDoc from "@/services/api/api-doc";

Vue.use(VueApexCharts)
//import responseFeedback from '@/assets/response_feedback.json'
export default {
  name: "Statistics",
  components: {
    Field,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        colors: [ '#226f54', '#CF0A2C',],
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: false,
          },
          selection: {
            enabled: false,
          }
        },
        noData: {
          style: {
            color: "grey"
          },
          text: this.$t('no-data')
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  shadow: false
                },
                value: {
                  shadow: false
                },
                total: {
                  show: false,
                  label: 'Всего',
                  color: '#373d3f',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              },
            }
          }
        }
      },
      barchartOptions: {
        colors: ['#226f54', '#CF0A2C'],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        noData: {
          style: {
            color: "grey"
          },
          text: this.$t('no-data')
        },
      },
      locationCount: [],
      totalCount: [],
      selectedLocations: [],
      selectedDepartments: [],
      locations: [],
      departments: [],
      results: [],
      showResults: true,
      loading: true,
      downloading: false,
      barChart: null,
      doughnutChart: null,
    }
  },
  computed: {
    doughnutChartSeries() {
      if (this.doughnutChart)
        return [
          this.doughnutChart.passed_users,
          this.doughnutChart.total_users - this.doughnutChart.passed_users
        ]
      return []
    },
    tasks() {
      return this.results?.tasks
    },
    barChartSeries() {
      if (this.barChart?.series) return this.barChart.series
      return []
    },
    barChartCategories() {
      if (this.barChart?.categories) return this.barChart.categories
      return []
    },
    getSelected() {
      return {
        locations: this.selectedLocations?.length > 0 ? this.selectedLocations : this.locations,
        departments: this.selectedDepartments?.length > 0 ? this.selectedDepartments : this.departments
      }
    },
  },
  watch: {
    location() {
      this.showResults = false
      setTimeout(() => {
        this.showResults = true
      }, 500)
    }
  },
  async created() {
    await this.getLocationsAndDepartments()
    await this.getCounts()
    await this.update()
    this.loading = false
  },
  methods: {
    update() {
      this.getResults()
      this.getBarChart()
      this.getCountChart()
    },
    async getCounts() {
      this.locationCount = await ApiSecure.get('/stats/locations/')
      this.totalCount = await ApiSecure.get('/stats/users/')
    },
    async getCountChart() {
      this.doughnutChart = await ApiSecure.post('/stats/by_locations_departments/', this.getSelected)
    },
    async getResults() {
      this.results = await ApiSecure.post('/stats/results/', this.getSelected)
    },
    async getBarChart() {
      this.barChart = await ApiSecure.post('/stats/chart_stats/', this.getSelected)
    },
    async getLocationsAndDepartments() {
      const { locations, departments} = await ApiSecure.get('/stats/all_locations/')
      this.locations = locations
      this.departments = departments
    },
    async downloadReport() {
      this.downloading = true;
      const link = await ApiDoc.get(`/stats/xlsx_stats/`);
      link.download = 'report.xlsx';
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        this.downloading = false;
      }, 200);
    },
    splitStringByWords(str, maxLength) {
      const words = str.split(/[\s-]+/)
      let result = [];
      let currentString = "";
      for (let i = 0; i < words.length; i++) {
        if (currentString.length + words[i].length <= maxLength) {
          currentString += words[i] + " ";
        } else {
          result.push(currentString);
          currentString = words[i] + " ";
        }
      }
      if (currentString.length > 0) {
        result.push(currentString);
      }
      return result;
    }
  }
}
</script>

<template>
  <v-container
    v-if="!loading"
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="11"
      >
        <p class="text-h4">
          Статистика
        </p>
        <v-btn
          color="secondary"
          depressed
          :loading="downloading"
          @click="downloadReport"
        >
          Скачать отчет
        </v-btn>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">
                  Локация
                </th>
                <th class="text-left">
                  Всего
                </th>
                <th class="text-left">
                  Прошло
                </th>
                <th class="text-left">
                  Не прошло
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">
                  Всего
                </td>
                <td class="text-left">
                  {{ totalCount.total_users }}
                </td>
                <td class="text-left">
                  {{ totalCount.passed_users }}
                </td>
                <td class="text-left">
                  {{ totalCount.total_users - totalCount.passed_users }}
                </td>
              </tr>
              <tr
                v-for="item in locationCount"
                :key="item.location"
              >
                <td class="text-left">
                  {{ item.location }}
                </td>
                <td class="text-left">
                  {{ item.total_users }}
                </td>
                <td class="text-left">
                  {{ item.passed_users }}
                </td>
                <td class="text-left">
                  {{ item.total_users - item.passed_users }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mt-16"
    >
      <v-col
        cols="11"
        sm="5"
        class="text-left"
      >
        <p class="text-h5">
          Статистика по выбранным локациям и департаментам
        </p>
        <v-select
          v-if="locations.length > 1"
          v-model="selectedLocations"
          :items="locations"
          multiple
          label="Выберите локацию"
          outlined
          dense
          clearable
          @change="update"
        >
          <template #selection="{item, index}">
            <div class="v-select__selection v-select__selection--comma">
              <span
                v-if="selectedLocations.length === 1 && index === 0"
              >
                {{ selectedLocations[0] }}
              </span>
              <span
                v-else-if="selectedLocations.length > 1 && index === 0"
              >
                {{ selectedLocations.length + ' выбрано' }}
              </span>
            </div>
          </template>
        </v-select>
        <v-select
          v-if="departments.length > 1"
          v-model="selectedDepartments"
          :items="departments"
          multiple
          label="Выберите департамент"
          outlined
          dense
          clearable
          @change="update"
        >
          <template #selection="{item, index}">
            <div class="v-select__selection v-select__selection--comma">
              <span
                v-if="selectedDepartments.length === 1 && index === 0"
              >
                {{ selectedDepartments[0] }}
              </span>
              <span
                v-else-if="selectedDepartments.length > 1 && index === 0"
              >
                {{ selectedDepartments.length + ' выбрано' }}
              </span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="11"
        sm="6"
      >
        <apexchart
          type="donut"
          :options="{
            ...chartOptions,
            labels: ['Прошло', 'Не прошло']
          }"
          :series="doughnutChartSeries"
        />
      </v-col>
      <v-col
        cols="11"
        class="mt-15"
      >
        <p class="text-h6 text-left">
          Сравнительная диаграмма по взаимодействию департаментов (%)
        </p>
      </v-col>
      <v-col
        cols="11"
      >
        <apexchart
          type="bar"
          :options="{
            ...barchartOptions,
            xaxis: {
              categories: barChartCategories.map(item => splitStringByWords(item, 20))
            }
          }"
          :series="barChartSeries"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mt-16"
    >
      <v-col cols="11">
        <p class="text-h6 text-left">
          Результаты опроса
        </p>
      </v-col>
      <v-col
        cols="11"
      >
        <v-progress-linear
          :value="33"
          :buffer-value="66"
          color="primary"
          background-color="warning"
          class="success mb-4"
          height="25"
        >
          <div
            style="
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
            height: 100%;
            line-height: 1.6;
            color: white"
          >
            <span>{{ "менее 51%" }}</span>
            <span>{{ "51% - 84%" }}</span>
            <span>{{ "более 84 %" }}</span>
          </div>
        </v-progress-linear>
      </v-col>
      <v-col cols="11">
        <v-expansion-panels
          :disabled="!showResults"
        >
          <v-expansion-panel
            v-for="({fieldsSchema, description},i) in tasks"
            :key="i"
          >
            <v-expansion-panel-header>
              <strong>{{ description }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left">
              <template
                v-for="(schema, index) in fieldsSchema"
              >
                <p
                  v-if="!!schema.name && schema.name !== 'NaN'"
                  :key="`name-${index}`"
                  class="name"
                  :class="{
                    'font-weight-bold': schema.type === 'nan'
                  }"
                >
                  {{ schema.name }}
                </p>
                <Field
                  v-if="schema.type !== 'nan'"
                  :key="`field-${index}`"
                  :schema="schema"
                  :index="index"
                  dense
                  disabled
                  readonly
                  highlight
                />
                <v-divider
                  v-if="schema.type !== 'nan' && index !== fieldsSchema.length - 1"
                  :key="`divider-${index}`"
                  class="mb-5"
                />
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>